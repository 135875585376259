
      .ancillary-pax-selector {
        &__header {
          @include clearfix;
          color: $brand-color-2;
          border-bottom: 0.0625rem solid $light-water-blue;
        }

        &__pax-details {
          @include make-row;
          width: inherit;
          margin: 0.3125rem;
          padding-top: 0.3125rem;

          &_current-leg {
            @include make-one-fifth;
            @include clearfix;
          }

        }

        .current-pax,
        .ancillary-price,
        .current-select,
        .current-ancillary {
          @include make-one-third;
          padding-left: 0rem;
          font-size: $font-size-h4;
        }
        .current-select {
          cursor: pointer;
          &__meal-name,
          &__meal-clear {
            float: left;
            height: 0.9375rem;
            margin-right: 0.3125rem;
          }
        }
        .current-select-not-available{
          float: left;
          padding-left: 0.9375rem;
          padding-right: 0.9375rem;
          min-height: 1px;
          width: 25%;
          margin: 0rem 0.3125rem;
          padding-left: 0rem;
          font-size: 0.875rem;
        }

        .ancillary-select{
          display: flex;
          &__edit,
          &__name,
          &__clear{
            margin-right: 0.3125rem;
          }
          &__clear,
          &__edit{
            cursor: pointer;
          }

          &__edit{
            color: $water-blue;
          }
          &__booked-ancillary{
	         cursor: pointer;
	          margin-left: 0.3125rem;
         }
          
        }

        .ancillary-price {
          font-family: $font-family-number;

          &_amount-with-adhoc {
            color : $brand-color-14;
          }
        }

      }