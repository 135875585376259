.endorsement-details-container{
  @include make-input;
  @include clearfix();
  &__message {
    height: 9rem;
    height: 10rem;
    margin-top: 60px;
    margin-left: 25px;
    font-size: 0.9375rem;
    font-weight: 700;
  }
  &__header{
	@include clearfix();
	width:100%;
	border-bottom: 1px solid $brand-color-2;
	&_title{
		float:left;
		    padding: 0.625rem;
    font-size: 1rem;
    color: $brand-color-2;
     padding-bottom: 0;
     padding-left: 0;
     font-weight: 700;
     
	}
	&_close{
		float: right;
    font-size: 1rem;
    padding: 0.625rem;
    padding-right: 0;
    padding-bottom: 0;
	}
	}
  &__message-container {
    &_title{
	margin-top:.5rem;
    font-weight: 700;
    font-size: 0.875rem;
    color: $brand-color-11;
    padding: 0.3125rem 0.3125rem 0.3125rem 0.4375rem;
    padding-left: 0;
}
    &_text{
	font-size: 0.875rem;
}
&_loader{
	margin-top: 1rem;
}
  }
  &__check-box-container{
	padding-top:2rem;
}
  &__done-button{
    float: right;
    > button{
	@include make-button($light, $brand-color-2, $light, darken($brand-color-2,20));
	   		margin-right: 0.125rem;
	   		margin-top: 1.625rem;
			padding: 0.525rem;
			width: 250px; 
    }
}
}

.modal-open {
  .endorsement-details {
    overflow: hidden;
  }
}
.endorsement-details{
  .modal-content {
    max-width: 70%;
    margin: auto;
    margin-top: 20%;
    padding-top: 0.25rem;
  }
}
