@use "sass:math";

.fare-family {
	width: 100%;
	position: relative;
	tr {
      border-bottom: none;
    }
    th, td {
      padding-left: math.div($base-padding, 6);
      padding-right: math.div($base-padding, 6);
      width: auto;
      line-height: 0.9rem;
    }
    table {
    	table-layout: fixed;
    }
	&__header {
		height: 3rem;
	    padding: 0.5rem;
	    border: 1px solid $brand-color-3;
    	display: block;
    	width: 100%;
    	display: flex;
  		justify-content: center;
  		align-items: center;
  		background: $brand-color-3;
  		text-transform: none;
		display: inline-flex;
		min-width: 12rem;
		&_fare-class {
			display: inline-block;
			width : 100%;
    		color: $brand-color-2;
    		text-align: center;
    		font-size: 0.75rem;
			white-space: nowrap;
    		text-overflow: ellipsis;
    		width: 10rem;
    		overflow: hidden;
			padding: 0 0.8rem 0 0.8rem;
		}
		&_fare-type {
			display: inline-block;
    		text-align: center;
    		font-size: 0.8rem;
		}
		&_amount, &_amount-with-adhoc {
			display: inline-block;
			width : 45%;
			font-size: 0.65rem;
			float: right;
			font-weight: 700;
		}
		&_amount-with-adhoc {
			color: $brand-color-14;
		}
		&_flight-pref-img {
			img {
				width: $base-padding*0.5;
				padding-bottom:0.5rem;
			}
		}
		&_flexi-info-icon {
			color: $brand-color-24;
		}
	}
	&__side-header {
		display: block;
		width: 2.5rem;
		color: $gray;
		position: absolute;
		top: 4.6rem;
    	left: 0.1rem;
		td,th {
			display: block;
			width: 2.5rem;
		}
		&_content {
    		text-align: left;
    		position: absolute;
    		left: 5px;
		}
	}
	&__table {
		width: 100%;
		td {
			text-align: center;
			padding: 0;
			min-height: 3rem;
    		height: 3rem;	
    		position: relative;		
		}
		tr {
			border: none;
    		height: 3rem;
		}
		&_link {
			display: block;
			width: 100%;
			text-align: center;
		}
		&_content, &_link {
			display: block;
    		width: 100%;
    		position: relative;
			a {
				width: 100%;
    			display: block;
			}
			.refundable-status {
				text-transform: uppercase;
			}
			.cabin-class {
				color: $brand-color-2;
				
			}
			.cabin-class-downgrade{
			  					color: $brand-color-37;
			  					font-size: .825rem;
			  					text-align: right;
			  					text-transform: uppercase;
			  					padding: 0rem 0.25rem;
			  }
		}
		&_link {
			line-height: 1.5rem;
		}
	}
	&__return-trip-header {
	    display: block;
    	margin: 0.2rem;
    	width: 98%;
    	text-align: center;
    	font-size: 0.7rem;
    	&_going-trip, &_coming-trip {
    		padding: 0.2rem;
    		border: 1px solid;
    		width: 7.5rem;
    		display: inline-block;
    		height: 2.5rem;
    		overflow: hidden;
    		background: $brand-color-3;
    		color: $brand-color-2;
    	}
	}
	&__services {
    	color: $brand-color-2;
    	display: block;
    	line-height: .9rem;
	    word-break: break-word;
	}
	
	&__flight-services {
		vertical-align: top;
	}

	&__flexi-flight-services {
		display: inline-block;
    	padding: 0.5rem;
    	width: 100%;
    	text-align: center;
	}
	&__flexi-info-icon {
		font-size: 0.9rem;
    	color: $brand-color-2;
    	padding-left: 0.5rem;
	}
	&__air-port-message-info-icons {
  		font-size: 1rem;
  		color: #ec2227;
	}
	&__scroll-button {
		&_prev-btn, &_next-btn {
			width: 0.9rem;
    		position: absolute;
    		font-size: 1.3rem;
    		cursor: pointer;
    		z-index: 1;
    		margin-top: 1.65rem;
    		color: $brand-color-2;
		}
		&_prev-btn {
			float: left;
    		left: 0.325rem;
		}
		&_next-btn {
			float: right;
    		right: 0.325rem;
		}
	}
	&__more-info {
		padding : 0.5rem;
		td {
    		height: auto;	
		}
		&_button {
			padding : 0.5rem;
			.more-info-button {
				border: 1px solid $brand-color-2;
    			padding: 0.2rem .5rem;
	    		border-radius: 0.3rem;
				color : $brand-color-2;
				cursor: pointer;
				&__plus, &__minus {
					padding-left: 0.2rem;
					font-size: 0.625rem;
				}
			}
		}
		&_flight-services {
			display : block;
			padding : 0.5rem;
			height : auto;
		}
	}
	&__flight-services-left-header {
		width : 9rem;
		display: block;
		color: $brand-color-2;
	}
	
	&__fetch-fare-loader {
		height: 15rem;
    	display: flex;
    	align-items: center;
    	justify-content: center;
    	flex-direction: column;
		&_content{
			color: $brand-color-2;
			text-align: center;
    		padding: $base-padding*0.5;
		}
	}
}

.fare-family-link {
	color: $brand-color-2 !important;
}

.active-fare-family-link {
	color: $brand-color-1 !important;
}

.fare-type-display, .reissue-error-message {
	color: $brand-color-1;
}

.more-fare {
	display: flex;
	height: 100%;
	position: relative;
	&__multi-trip-fare-list, &__multi-trip-fare-list-on-view-details {
		display: block;
		float: left;
		width: 65%;
    	height: 100%;
    	tr {
		    border-bottom: 1px solid $brand-color-3;
		}
	}
	
	&__multi-trip-fare-list-on-view-details{
		width: 100%;
    	border-right: none;
	}
	&__multi-trip-fare-details {
		display: block;
		float: right;
		width:35%;
		padding-left: 0.5rem;
	}
	&__separator {
		display: block;
    	position: absolute;
    	top: 0;
    	bottom: 0;
    	border-right: 10px solid $brand-color-3;
    	right: 34%;
	}
	&__side-header {
		display: block;
    	width: 100%;
		text-align: left;
		&_label {
			display: inline-block;
    		width: 47%;
			color: $brand-color-2;
			padding-left: 0.5rem;
		}
		&_icon {
			width: 50%;
    		display: inline-block;
		}
	}
}

.flight-service-tooltip {
	width: auto;
	min-width: 120px;
}