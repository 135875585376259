.tax {
  @include create-row-card;
  @include create-row-card-header;
  @include create-card-content;
  @include create-row-card-footer;
  @include create-flight-row;
  &_card {
    box-shadow: $row-shadow-color;
    &_header {
      text-transform: uppercase;
      color: $brand-color-2;
    }
    .header_text {
      margin-right: $base-margin;
    }
  }
  &__table {
    font-family: $font-family-number;
    width: 100%;
    &_total {
      background-color: $brand-color-4;
      color: $dark;
    }
    tr {

      //padding: inherit;
      td {
        border-bottom: 1px solid lighten($brand-color-5, 20);
        padding-top: 0.625rem;
        padding-bottom: 0.3125rem;

        //padding-left: 0.625rem;
        //padding-right: 0.625rem;
        font-weight: 600;
        &:first-child {

        }
        &:last-child {
          text-align: right;
        }
      }
    }
  }
}