.other-ancillary-selector {
    @include create-main-wrapper;
    @include create-row;
    @include create-row-card;
    @include create-row-card-header;
    @include create-card-content;
    @include clearfix;
    padding-top: $base-padding*0.5;
    padding-bottom: $base-padding*0.5;

    .content {
        padding-left: 0 !important;
        padding-right: 0;
        width: 100%;
    }

    &__card {
        box-shadow: $card-shadow-color;
        background-color: $ancillary-container-bg-color;
        border-top-left-radius: 0.3125rem;
        border-top-right-radius: 0.3125rem;

        &_header {
            @include clearfix;
            text-transform: uppercase;
       		color: $ancillary-header-font-color;
        	background: $ancillary-header-bg-color;
            padding-left: 0.3125rem;
            height: 3.125rem;
        }

        .other-ancillary-expander {
            float: left;
            padding-left:  0.625rem;
            padding-right:  0.625rem;
            width: 2.813rem;
            padding-top: 0.5rem;

        }

        .other-ancillary-heading {
            font-size: $font-size-h3;
            float: left;
            padding-top: 0.375rem;

            &__container {
                display: flex;
                align-items: center;
                height: 2.5rem;
                padding-left: 0.9375rem;
                &_header-text {
                    padding-left: 0.3125rem;
                }
            }
        }

        .other-ancillary-price {
            font-size: $font-size-h3;
            float: right;
            padding-top: 0.75rem;
            padding-right: 0.75rem;

            &__currency-code{
                font-size: 1.25rem;
                float: left;
                margin-right: 0.25rem;
              }
              &__currency-rate{
                float: right;
                font-family: $font-family-number;
                font-size: 1.25rem;
              }
        }

        &_container {
            padding-left:  0.625rem;
            margin:  0.625rem 0.3125rem;
            @include clearfix;

            .other-ancillary-leg-selector {
                @include clearfix;
               	background: $ancillary-tab-bg-color;
          		color: $ancillary-tab-font-color;
            }
            .other-ancillary-type-selector{
                padding-left: .9375rem;
                padding-right: .9375rem;
                width: 45%;
                margin-top: 0.9375rem;
                &__title{
                    margin-bottom: .25rem;
                    color: $brand-color-2;
                    font-size: .875rem;
                    float: left;
                    padding-right:  0.625rem;
                    margin-top: 0.3125rem;
                }
                select {
                    width: 50%;
                    padding: .5rem 1rem;
                    background: none;
                    font-size: .875rem;
                    vertical-align: middle;
                    line-height: normal;
                    border: 1px solid #d0d0d0;
                    max-height: 2.125rem;
                }
            }
            .card-content{
                padding-bottom: 0.625rem;
              }
        }
    }
}