.selected-ancillary {
    padding-top: 0.9375rem;

    &__card {
        box-shadow: $row-shadow-color;
        background-color: $ancillary-summary-bg-color;
        margin-bottom: .9375rem;

        &_header {
            background-color: $ancillary-summary-header-bg-color;
			color: $ancillary-summary-header-font-color;
            padding-top: .625rem;
            padding-bottom: .3125rem;
            font-weight: 600;
            font-size: .75rem;
            text-align: center;
            text-transform: uppercase;
            border-bottom: 0.0625rem solid $separator-color;
        }

        &_container {
            @include clearfix;
            width: 100%;
            padding: 0.5rem;

            .selected-seat-details,
            .selected-baggage-details,
            .selected-meal-details,
            .selected-other-ancillary-details {
                @include clearfix;
                width: 100%;
                padding: 0.1rem;


                &__quantity,
                &__title {
                    float: left;
                    width: 38%;
                    padding-right: 0.5rem;
                }

                &__title {
                    width: 40%;
                }

                &__price {
                    @include clearfix;
                    padding-left: 0.975rem;
                }
            }

            .selected-baggage-details,
            .selected-meal-details,
            .selected-other-ancillary-details {
                &__baggage-details,
                &__meal-details,
                &__other-ancillary-details {    
                    float: left;
                    width: 60%;

                    &_baggage-detail,               
                    &_meal-detail,
                    &_other-ancillary-detail{
                        .ancillary-name{
                            float: left;
                            width: 70%;
                        }
                        .ancillary-rate{
                            float: left;
                            width: 30%;
                            text-align: right;
                        }   
                    }
                }
            }

            .pax-details {
                @include clearfix;
                border-bottom: 0.0625rem solid $dark;
                padding: 0.625rem 0rem;
                color: $brand-color-1;
                width: 100%;

                &__expander {
                    float: left;
                    margin-right: 0.625rem;
                }

                &__title {
                    float: left;
                    width: 60%;
                }
                &__price{
                    @include clearfix;
                    padding-left: 1.5rem;
                    font-family: $font-family-number;
                    text-align: right;
                }
            }

            .flight-info{
                padding-top: 0.625rem;
                &__title,
                &__details{
                    width: 100%;
                }

                &__title{
                    color: $ancillary-summary-title-font-color;
                }
                &__details{
                    width: 100%;
                    border-bottom: 0.0625rem solid $separator-color;
                    @include clearfix;
                    &_name{
                        float: left;
                        width: 35%;
                    }
                    &_detail{
                        float: right;
                    }
                }
            }
            .selected-seat-details{
                &__quantity{
                    width: 30%  !important;
                }
                &__price{
                    float: left;
                    width: 30%;
                    text-align: right;
                }
            }
        }

        &_foooter {
            .total-price {
                @include clearfix;
                padding-top: .5rem;
                padding-bottom: .5rem;
                color: $brand-color-2;
                width: 100%;
                border-bottom: .03125rem solid $separator-color;
                border-top: .03125rem solid $separator-color;

                &__title {
                    float: left;
                    width: 45%;
                    text-align: left;
                }

                &__price {
                    text-align: right;
                    font-family: $font-family-base;

                    &_currency-code{
                        float: right;
                        margin-right: 0.1rem;
                      }
                    &_currency-rate{
                        float: right;
                        font-family: $font-family-number;
                      }
                }
            }
        }
    }
}