@use "sass:math";

.flight-details,.pnr-flight-details,.flexi-flight-details {
  // background-color: $brand-color-4;
  // border: 10px solid $brand-color-3;
  // border-left: 10px solid $brand-color-3;
  // border-right: 10px solid $brand-color-3;
  // border-bottom: 10px solid $brand-color-3;
  // padding: 0 $base-padding/3 $base-padding/3;
   
   
 	.nav {
      background-color: $light-gray;
      content:"";
      display:table;
      width:100%;
    }
    .nav-tabs {
      border-bottom: none;
    }
    .nav-tabs > li > a {
      margin-right: 0;
      border: none;
      border-radius: 0;
      padding: 8px 45px;
      color: $gray;
      font-size: $font-size-h5;
    }
    .nav > li > a:hover,
    .nav > li > a:focus {
      background-color: $light;
    }
    .nav-tabs > li.active > a,
    .nav-tabs > li.active > a:hover,
    .nav-tabs > li.active > a:focus {
      color: $brand-color-1;
      background-color: $light;
      border: none;
      cursor: default;
    }            
    
   
  margin-left: -15px;
  margin-right: -15px;
  padding-left: math.div($base-padding, 3);
  padding-right: math.div($base-padding, 3);
  padding-bottom: math.div($base-padding, 3);
  &__header {
    background-color: $brand-color-4;
    border-left: 10px solid $brand-color-3;
    border-right: 10px solid $brand-color-3;
    border-top: 10px solid $brand-color-3;
  }
  &__list {

    // display: inline-block;
    @include clearfix;
    >li {
      padding-top: 5px;
      padding-bottom: 2px;
      padding-left: 15px;
      padding-right: 15px;
      float: left;
      // border-top: 3px solid $brand-color-3;
      // border-left: 3px solid $brand-color-3;
      // border-right: 3px solid $brand-color-3;
      > a {
        color: $brand-color-5;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
      }

      //
      // .active{
      //   background-color: $brand-color-3;
      //   color: $brand-color-2;
      // }
    }
  }
  &__tab-content {
    background-color: $light;
    border-left: 10px solid $brand-color-3;
    border-right: 10px solid $brand-color-3;
    border-bottom: 10px solid $brand-color-3;
    border-top: 10px solid $brand-color-3;
    // padding: 5px;
  }
  &__change-fare-tab-content {
    background-color: $light;
    border-left: 10px solid $brand-color-2;
    border-right: 10px solid $brand-color-2;
    border-bottom: 10px solid $brand-color-2;
    border-top: 10px solid $brand-color-2;
    
    &_fare {
    	height: auto;
    	min-height: 1rem !important;
    	padding: 0;
    }
    &_fare-type {
    	color: $brand-color-2;
    }
    &_book {
    	width: 100% !important;
    }
  }
  .active {
    background-color: $light;
    a {
      color: $brand-color-1;
      &:hover{
      cursor: pointer;
      text-decoration: underline;
             }
    }
  }
}

.flexi-flight-details{
 	padding-left: 0.9375rem;
   	padding-right: 0.9375rem;
   	.nav-tabs > li > a {
   		padding: 8px 10px;
   	}
   	&__extra-header {
   		.nav-tabs > li > a {
   			padding: 8px 3.9px;
   		}
   	}
}

.nearby-airport-modal {
    font-size: 0.75rem;
    @include clearfix;
    font-weight: 600;
    text-align: center;
	    &__content {
	    	border: 2px solid $brand-color-2;
	    	padding: 0.9375rem;
			&_text{
		    	color: $brand-color-2;
		    	padding-bottom: math.div($base-padding, 3);
    			font-size: 0.875rem;
		    	margin-top: 5%;
    			text-align: left;
	    	}
	    &_buttons, .buttons{
			@include clearfix;
	    	min-width: 12.5rem;
	    	padding: .9375rem;
	    	width: 100%;
	    	&__close-button, &__okay-button{
	    		color: $brand-color-2;
	    		width: 12%;
				padding: $base-padding*0.2;
				background-color: $brand-color-3;
				font-weight: 600;
	    	}
			&__okay-button{
				float: right;
			}
			&__close-button{
				float: left;
			}
	    }
	    &_close {
	    	float: right;
    		cursor: pointer;
	    	color: $brand-color-2;
	    }
	}
}
