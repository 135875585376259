@use "sass:math";

.fare-details,.flexi-fare-details {
  width: 100%;
  font-family: $font-family-number;
  &__table {
    width: 100%;
    thead {
      th {
        // font-weight: normal;
        color: $brand-color-2;
        text-transform: uppercase;
      }
    }
    tbody {
      tr:last-child {
        border-bottom: none;
        // background-color: $brand-color-3;
        margin-left: -5px;
        margin-right: -5px;
      }

    }
    tr {
      border-bottom: 1px solid $brand-color-3;
    }
    td {
      text-align: right;
    }
    th {
    &:last-child {
      	 text-align: right;
      }
    }
    th,
    td {
      padding-top: math.div($base-padding, 3);
      padding-bottom: math.div($base-padding, 6);
      padding-left: math.div($base-padding, 3);
      padding-right: math.div($base-padding, 3);
      font-weight: 600;
      width:10%;
    }
  }
  // .pax-header {
    th,td {
      text-align: right;
      width:10%;
      &:first-child {
        text-align: right;
        width:10%;
      }
      // &:nth-child(2) {
      //   text-align: center;
      // }
      // &:last-child {
      //   text-align: right;
      // }
    }
  // }
  .pax-no {
    text-align: center;
  }
  // .transaction-fees {
  //   text-align: left;
  // }
  .grand-total {
    background-color: $brand-color-4;
    color: $dark;
  }
  .tax-breakup__button {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    background-color: $brand-color-1;
    border: none;
    line-height: inherit;
    -webkit-border-radius: 0;
    border-radius: 0;
    background-image: none;
    width: 100%;
    color: $light;
    font-weight: 700;
    display: none;
  }
}
 .flexi-fare-details{
 	&__table{
 		&_fare-currency{
 			float: left;
 			padding-left: 0.8125rem;
 		}
	
		&_fare-amount{
			float: right;
		}
	
 		table,th,td {
 	      	width: 16%;
 	      	padding-top: 0.125rem;
 		  	padding-bottom: 0.1875rem;
 	    }
 	    table{
 	    	border-collapse: collapse;
 	    }
 	      
 	}
 	&__adhoc-table{
 		
 		table,th,td {
 			width: 25%;
 	      	padding-top: 0.125rem;
 		  	padding-bottom: 0.1875rem;
 	    }
 	    
 	    tr {
     	 border-bottom: 1px solid $brand-color-3;
    	}
 	    	
 	}
 	&__adhoc-footer{
 		width: 100%;
   		height: 1.8rem;
    	background-color: $light-gray;
 	}
 	
 }
 
 .baggage-details{
	&__header{
	    width: 100%;
	    display: table;
	    border-bottom: 1px solid $brand-color-3;
	    color: $brand-color-2;
	    &_pax-type,&_baggage-type {
		    float: left;
		    text-align: center;
    		padding: 0.5rem;
    		text-transform: uppercase;
		}
		&_baggage-type {
			width: 40%;
		}
		&_pax-type {
			width: 20%;
		}
	}
	&__row {
	    width: 100%;
	    display: table;
	    border-bottom: 1px solid $brand-color-3;
	    &_sector,&_baggage-units {
		    float: left;
		    text-align: center;
    		padding: 0.5rem;
		}
		&_sector {
			width: 40%;
		}
		&_baggage-units{
			width: 20%;
			text-transform: uppercase;
		}
		&_baggage-info-icon {
    		font-size: 1rem;
    		color: $brand-color-2;
		}
	}
	&__message{
    		padding: 1rem;
    	}
	}
