.search__detail {
  @include create-row;
  @include create-row-card;
  @include create-row-card-footer;
  @include create-flight-row;
  
  .flight-details{
   	float: left;
   	text-transform: uppercase;
   	padding-left: 0;
   	padding-right: 0;
     
     &__flexi-flight{
     	padding-right: 0;
     }
   }
   .flexi-duration{
   	padding-left: 0;
     padding-right: 0;
   }
   
  &_trip-title {
    padding-top: 15px;
    padding-bottom: 5px;
    font-size: 14px;
    text-transform: uppercase;
    color: $dark;
  }
  &:not(:first-child) .tripTitle {
    border-top: 2px solid $brand-color-2;
  }
  
  .trip-title{
   	padding-top: 0.625rem;
   		
   	&__from-city{
     	color: $dark;
     	float: left;
     	padding-left: 0.5rem;
     	font-size: 0.875rem;
     	text-transform: capitalize;
     }
   }
  
  &_card {

    // box-shadow: $row-shadow-color;
  }
}
.detail {
  @include create-flight-detail-row;
  &__flight-name {
    @include clearfix();
    &_checkbox {
      float: left;
      width: 10%;
    }
    &_text {
      float: left;
      text-align: center;
      width: 90%;
    }
  }
}
.details__details {
  &_layover,
  &_return {
    position: relative;
    text-align: center;
    &:after {
      position: absolute;
      width: 100%;
      height: 1px;
      top: 50%;
      left: 0;
      content: "";
    }
  }
  &_layover-time{
	    .layover-clock{
	    	font-size: 1.25rem;
		    color: $brand-color-9;
		    text-align: center;
	    }
	    .leg-stop-location{
	    	font-size: 0.875rem;
    	    text-align: center;
	    }
	    .layover-time{
	    	position: relative;
			text-align: center;
			&:after {
	            position: absolute;
				width: 100%;
				height: 0.0625rem;
				top: 0%;
				content: "";
				right: 0;
				border: 0.0625rem dashed lighten($brand-color-5, 20);
			}
	    }
	}
  
  &_return {
    color: $brand-color-2;
  }
  &_layover:after {
    background-color: lighten($brand-color-5, 20);
  }
  &_return:after {
    background-color: $brand-color-3;
  }
}
.change__info {
  text-align: center;
  position: relative;
  z-index: 1;
  padding-left: $base-padding*0.5;
  padding-right: $base-padding*0.5;
  background-color: $light;
}
.show__more {
  background-color: $brand-color-4;
  border-top: 1px solid $brand-color-3;
  margin-top: -15px;
  margin-bottom: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  a {
    color: $dark;
  }
}
.more__result {
  margin-top: -15px;
}

 .fare-tab-data{
 	padding: 0.5rem;
 }
