.pnr-pax-selector-container{
	@include clearfix();
    width: 100%;
    margin-bottom: 1rem;
    &__header{
	@include clearfix();
    font-size: $font-size-h3;
    background-color: lighten($brand-color-3,8);
    padding: .5rem;
&_pax-name{
	padding-left:.5rem;;
	    float: left;
    width: 60%;
    color: $brand-color-2;
    &-label{
	padding-left: 0.9375rem;
}
}

&_pax-type{
	   float: left;
    width: 40%;
	color: $brand-color-2;
}
    
}
}

.pax-details-row{
	@include clearfix();
	width: 100%;
    background: $light;
    padding: 0.5rem;
    font-size: 0.875rem;
    padding-left: 0.9375rem;
	&__pax-name{
		float:left;
		width:60%;
		color: $brand-color-2;
		
		&_check-box{
			padding-right: 1rem;
		}
		&_name{
			    &-selected{
	color: $brand-color-32;
}
		}
	}
	&__pax-type{
		float:left;
		width:40%;
		color: $brand-color-2;
	}
}