.fare-card {
  @include travel-card;
  &__table {
    width: 100%;
    font-family: $font-family-number;
    &_total {
      background-color: $brand-color-4;
      color: $dark;
    }
    tr {

      //padding: inherit;
      td {
        border-bottom: 1px solid lighten($brand-color-5, 20);
        padding-top: 0.625rem;
        padding-bottom: 0.3125rem;

        //padding-left: 0.625rem;
        //padding-right: 0.625rem;
        font-weight: 600;
        &:first-child {

        }
        &:last-child {
          text-align: right;
        }
      }
    }
  }
}